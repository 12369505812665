import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import history from './../history';

export class Membership extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="membership-heading">
              <h1>Membership plans</h1>
              <p>Select our multiple membership plan and find your best life partner with membership benefits</p>
              <h4>Upgrade Now & Get Premium benefits for upto 4 weeks extra, <span>FREE!</span></h4>
            </div>
            <Row>
              <Col md={4}>
                <div className="membership-main-block">
                  <div className="memhead-blk">
                    <h4><b>Gold</b> 3 months</h4>
                    <h5>40% off <span>₹ 4999</span></h5>
                    <h3>₹ 2999</h3>
                    <p>₹ 999 per month</p>
                    <NavLink to="/Checkout" className="btn btn-primary">Continue</NavLink>
                  </div>
                  <ul>
                    <li>Send unlimited Messages</li>
                    <li>View uptp 20 Premium Profiles</li>
                    <li>View uptp 75 Contact Numbers</li>
                    <li>View upto 75 Email Ids</li>
                    <li>Standout from other Profiles</li>
                    <li>Let Matches contact you directly</li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div className="membership-main-block">
                  <div className="memhead-blk">
                    <div className="featuremem-blk">Feature</div>
                    <h4><b>Gold Plus</b> 3 months</h4>
                    <h5>40% off <span>₹ 6999</span></h5>
                    <h3>₹ 4999</h3>
                    <p>₹ 1599 per month</p>
                    <NavLink to="/Checkout" className="btn btn-primary">Continue</NavLink>
                  </div>
                  <ul>
                    <li>Send unlimited Messages</li>
                    <li>View uptp 20 Premium Profiles</li>
                    <li>View uptp 75 Contact Numbers</li>
                    <li className="notactive">View upto 75 Email Ids</li>
                    <li className="notactive">Standout from other Profiles</li>
                    <li className="notactive">Let Matches contact you directly</li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div className="membership-main-block">
                  <div className="memhead-blk">
                    <h4><b>Diamond</b> 6 months</h4>
                    <h5>40% off <span>₹ 9999</span></h5>
                    <h3>₹ 8999</h3>
                    <p>₹ 1999 per month</p>
                    <NavLink to="/Checkout" className="btn btn-primary">Continue</NavLink>
                  </div>
                  <ul>
                    <li>Send unlimited Messages</li>
                    <li>View uptp 20 Premium Profiles</li>
                    <li>View uptp 75 Contact Numbers</li>
                    <li>View upto 75 Email Ids</li>
                    <li className="notactive">Standout from other Profiles</li>
                    <li className="notactive">Let Matches contact you directly</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Membership 