import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class ActiveMember extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Active Members</h1>
              <p>See all active members</p>
            </div>
            <Row>
              <Col md={3}>
                <div className="match-profile-block members-blk-cust">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-1.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </Col>
              <Col md={3}>
                <div className="match-profile-block members-blk-cust">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-2.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </Col>
              <Col md={3}>
                <div className="match-profile-block members-blk-cust">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-3.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </Col>
              <Col md={3}>
                <div className="match-profile-block members-blk-cust">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-4.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </Col>
              <Col md={3}>
                <div className="match-profile-block members-blk-cust">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-5.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </Col>
              <Col md={3}>
                <div className="match-profile-block members-blk-cust">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-2.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </Col>
              <Col md={3}>
                <div className="match-profile-block members-blk-cust">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-3.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </Col>
              <Col md={3}>
                <div className="match-profile-block members-blk-cust">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-4.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default ActiveMember 