import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class SignUpEducationDetails extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
            <Container>
                <div className="signup-inner-block">
                    <div className="heading-title-block">
                        <h3>Update educational and career details</h3>
                        <p>Complete the educational and career details.</p>
                    </div>
                    <div className="form-block">
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Educational Qualification</Form.Label>
                                        <Form.Control as="select" className="form-select">
                                            <option value="">Select Educational Qualification</option>
                                            <option value="BA">BA</option>
                                            <option value="MA">MA</option>
                                            <option value="B. Tech">B. Tech</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>College/University</Form.Label>
                                        <Form.Control type="text" placeholder="College/University" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Working With</Form.Label>
                                        <Form.Control as="select" className="form-select">
                                            <option value="">Select Working With</option>
                                            <option value="Government">Government</option>
                                            <option value="Private">Private</option>
                                            <option value="NGO">NGO</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Working as</Form.Label>
                                        <Form.Control as="select" className="form-select">
                                            <option value="">Select Working as</option>
                                            <option value="Manager">Manager</option>
                                            <option value="Engineer">Engineer</option>
                                            <option value="Developer">Developer</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Annual Incom</Form.Label>
                                        <Form.Control as="select" className="form-select">
                                            <option value="">Select Annual Incom</option>
                                            <option value="1 Lac to 5 Lac">1 Lac to 5 Lac</option>
                                            <option value="5 Lac to 10 Lac">5 Lac to 10 Lac</option>
                                            <option value="10 + Lac">10 + Lac</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Button variant="primary full-btn" type="submit" onClick={() => history.push('/HomeDashboard')}>Sign Up</Button>
                                    </Form.Group>
                                    <div className="skip-block"><NavLink to="#">Skip this</NavLink></div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default SignUpEducationDetails 