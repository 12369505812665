import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Navbar, Nav, Button, Modal, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

export class Header extends Component {  
  render() {
    return (
        <div className="header-section">
            <div className="top-header">
            <Container>
                <Row>
                <Col md={6}>
                    <p className="welcome-text">Welcome to Bin Dahej</p>
                </Col>
                <Col md={6}>
                    <ul className="top-menu-list">
                    <li className="hide-on-mobile"><a href="tel:+919999999999">Helpline +91 999 999 9990</a></li>
                    <li><NavLink to="/Login">Login</NavLink></li>
                    <li className="registration-menu"><NavLink to="/SignUp">Registration</NavLink></li>
                    </ul>
                </Col>
                </Row>
            </Container>
            </div>
            <Navbar expand="lg">
            <Container>
                <NavLink className="navbar-brand" to="/"><img src= {'images/logo.png'} /></NavLink>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav className="ml-auto">
                    <NavLink className="nav-link" to="/">Home</NavLink>
                    <NavLink className="nav-link" to="/About">About</NavLink>
                    <NavLink className="nav-link" to="/ActiveMember">Active menber</NavLink>
                    <NavLink className="nav-link" to="/Membership">Premium Plans</NavLink>
                    <NavLink className="nav-link" to="/HappyStory">Happy Stories</NavLink>
                    <NavLink className="nav-link" to="/Contact">Contact</NavLink>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
        </div>
    );
  }
} 
export default Header 