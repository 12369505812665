import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class PostSuccessStory extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Happy Marriages</h1>
              <p>Check it out our success stories who found their life partner here.</p>
            </div>
            <div className="success-stories-blk">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="success-heading">
                    <h3>Post Success Story</h3>
                    <p>Post your success story here</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="write-story-btn-blk">
                    <NavLink to="/HappyStory" className="btn btn-primary">View Success Story</NavLink>
                  </div>
                </Col>
              </Row>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Bride Id</Form.Label>
                      <Form.Control type="text" placeholder="Bride Id" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Bride Name</Form.Label>
                      <Form.Control type="text" placeholder="Bride Name" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Groom Id</Form.Label>
                      <Form.Control type="text" placeholder="Groom Id" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Groom Name</Form.Label>
                      <Form.Control type="text" placeholder="Groom Name" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Engagement Date</Form.Label>
                      <Form.Control type="text" placeholder="Engagement Date" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Marriage Date</Form.Label>
                      <Form.Control type="text" placeholder="Marriage Date" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formFile" className="upload-file-blk mb-3">
                      <Form.Label>Upload Picture</Form.Label>
                      <Form.Control type="file"/>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Country Living In</Form.Label>
                      <Form.Control type="text" placeholder="Country Living In" />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Address</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Address" />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Success Story </Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Enter your success story here" />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default PostSuccessStory 