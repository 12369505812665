import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';

export class HomeDashboard extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="banner-section">
          <OwlCarousel items={1} className="owl-theme" loop autoplay nav margin={0} >
            <div className="item" style={{backgroundImage: 'url(images/slide-1.jpg)'}}>
              {/* <div className="banner-content">
                <h3>Every Love Story is Beautiful <span>Make Yours Special</span></h3>
              </div> */}
            </div>
            <div className="item" style={{backgroundImage: 'url(images/slide-2.jpg)'}}>
              {/* <div className="banner-content">
                <h3>Every Love Story is Beautiful <span>Make Yours Special</span></h3>
              </div> */}
            </div>
          </OwlCarousel>  
        </section>
        <section className="profile-info-section">
          <Container>
            <div className="profile-home-inner-block">
              <div className="profile-img-blk">
                <img src= {'images/porfile-2.jpeg'} />
              </div>
              <div className="profile-content-block">
                <h3>Rahul Singh <span>|</span> BD00001</h3>
                <p>rahulsingh@gmail.com <span>|</span> +91 999 999 9999</p>
                <p>rahulsingh@gmail.com <span>|</span> +91 999 999 9999</p>
                <div className="add-details-profile">
                  <p>Add details to your profile</p>
                  <p>Upload Photos +20% <span>|</span> Add Family Details +25% <span>|</span> Write about you & family +30%</p>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className="lets-started-section">
          <Container>
            <div className="heading-block-center">
              <h3>Welcome <span>Rahul Singh!</span> Let's get started</h3>
              <p>We are glad to have you as a member of Bin Dahej where lakhs of people connect with each other to discover their perfect match!</p>
            </div>
            <Row>
              <Col md={3}>
                <div className="showcase-block">
                  <i className="far fa-heart"></i>
                  <div className="showcase-content">
                    <h4>38</h4>
                    <p>Shortlisted Members</p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="showcase-block">
                  <i className="fas fa-link"></i>
                  <div className="showcase-content">
                    <h4>89</h4>
                    <p>Connections</p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="showcase-block">
                  <i className="far fa-paper-plane"></i>
                  <div className="showcase-content">
                    <h4>24</h4>
                    <p>Interest Send</p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="showcase-block">
                  <i className="far fa-eye"></i>
                  <div className="showcase-content">
                    <h4>76</h4>
                    <p>My Profile Viewed</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="matches-slider-section">
          <Container>
            <h4>Daily Recommendations <span>(45)</span></h4>
            <OwlCarousel items={4} className="owl-theme owl-custom-slider" loop autoplay nav margin={20} >
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-1.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-2.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-3.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-4.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-5.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </OwlCarousel>  
          </Container>
        </section>
        <section className="matches-slider-section">
          <Container>
            <h4>Just Joined <span>(122)</span></h4>
            <OwlCarousel items={4} className="owl-theme owl-custom-slider" loop autoplay nav margin={20} >
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-5.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-4.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-1.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-3.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="item">
                <div className="match-profile-block">
                  <NavLink to="/ViewMember">
                    <div className="match-profile-img">
                      <img src= {'images/bd-profile-2.jpg'} />
                    </div>
                    <div className="match-profile-cont">
                      <h3>Sonia Ray</h3>
                      <p>23 years, MBA</p>
                      <p>Hindi, Mushlim</p>
                      <p>Lucknow, uttar Pradesh</p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </OwlCarousel>  
          </Container>
        </section>
        <section className="membership-section">
          <Container>
            <div className="heading-block-center">
              <h3><span>Membership</span> Plans</h3>
              <p>Upgrade your plan as per your customized requirements. With a paid membership, you can seamlessly connect with your prospects and get more responses. Here are some key benefits:</p>
            </div>
            <div className="membership-free-paid-blk">
              <Row className="g-0 align-items-center">
                <Col md={6}>
                  <div className="membership-block">
                    <h3>Free</h3>
                    <ul>
                      <li><div className="materialIcons pinktick"></div> <span>Browse Profiles</span></li>
                      <li><div className="materialIcons pinktick"></div> <span>Shortlist & Send Interest</span></li>
                      <li><div className="materialIcons greyCross"></div> <span className="grey-text">Message and chat with unlimited users</span></li>
                      <li><div className="materialIcons greyCross"></div> <span className="grey-text">View contacts of members you like</span></li>
                      <li><div className="materialIcons greyCross"></div> <span className="grey-text">Priority customer support</span></li>
                      <li><div className="materialIcons greyCross"></div> <span className="grey-text">Make your contacts visible to others</span></li>
                      <li><div className="materialIcons greyCross"></div> <span className="grey-text">Profile Boost</span></li>
                    </ul>
                    <NavLink to="/Membership" className="btn btn-primary d-block">Upgrade</NavLink>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="membership-block paid-membership">
                    <h3>Paid</h3>
                    <ul>
                      <li><div className="materialIcons whiteTick"></div> <span>Browse Profiles</span></li>
                      <li><div className="materialIcons whiteTick"></div> <span>Shortlist & Send Interest</span></li>
                      <li><div className="materialIcons whiteTick"></div> <span>Message and chat with unlimited users</span></li>
                      <li><div className="materialIcons whiteTick"></div> <span>View contacts of members you like</span></li>
                      <li><div className="materialIcons whiteTick"></div> <span>Priority customer support</span></li>
                      <li><div className="materialIcons whiteTick"></div> <span>Make your contacts visible to others</span></li>
                      <li><div className="materialIcons whiteTick"></div> <span>Profile Boost</span></li>
                    </ul>
                    <NavLink to="/Membership" className="btn btn-white d-block">Upgrade</NavLink>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section className="real-wedding-section">
          <Container>
            <div className="heading-block-center">
              <h3>Be Inspire by <span>Real Wedding</span></h3>
              <p>Find inspiration for your Special Day. Yours could be the next Success Story</p>
            </div>
            <Row>
              <Col md={4}>
                <div className="successstory-blk">
                  <img src= {'images/couple-1.jpg'} /> 
                  <div className="successstory-cont">
                    <h4>Prem and Kiran</h4>
                    <p>Prem and Kiran. Well we met on bindahej.com. I was in search of an educated,decent person with well family background. One day we got a call from my father-in-law and their approach made us ...</p>
                  </div>
                  <NavLink to="/HappyStoryDetail" className="readmore-btn">Read More</NavLink>
                </div>
              </Col>
              <Col md={4}>
                <div className="successstory-blk">
                  <img src= {'images/couple-2.jpg'} /> 
                  <div className="successstory-cont">
                    <h4>Prem and Kiran</h4>
                    <p>Prem and Kiran. Well we met on bindahej.com. I was in search of an educated,decent person with well family background. One day we got a call from my father-in-law and their approach made us ...</p>
                  </div>
                  <NavLink to="/HappyStoryDetail" className="readmore-btn">Read More</NavLink>
                </div>
              </Col>
              <Col md={4}>
                <div className="successstory-blk">
                  <img src= {'images/couple-3.jpg'} /> 
                  <div className="successstory-cont">
                    <h4>Prem and Kiran</h4>
                    <p>Prem and Kiran. Well we met on bindahej.com. I was in search of an educated,decent person with well family background. One day we got a call from my father-in-law and their approach made us ...</p>
                  </div>
                  <NavLink to="/HappyStoryDetail" className="readmore-btn">Read More</NavLink>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="button-block">
                  <a href="#" className="viewmore-btn">View Happy Stories</a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="upgrade-membership-section">
          <Container>
            <div className="heading-block-center">
              <h3>Upgrade your membership to connect with those you like.</h3>
              <p>Search your perfect life-partner fron millions of trusted & authentic profiles.</p>
            </div>
            <Row>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/phone.png'} />
                    <h4>View Contacts</h4>
                    <p>Access contact number and connect directly on call or via sms.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/message.png'} />
                    <h4>View Email</h4>
                    <p>Get the email id of your selected profile and reach via email</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/chat.png'} />
                    <h4>Chat</h4>
                    <p>Chat instantly with all other online users</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="button-block">
                  <a href="#" className="viewmore-btn">Browse Membership Plans</a>
                  <p>To know more, call us @ +91 999 999 9999 (India)</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default HomeDashboard 