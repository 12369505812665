import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

export class DashboardNavigation extends Component {  

  render() {
    return (
      <div className="dashboard-sidebar">
        <div className="user-dashboard-profile-block">
          <div className="profile-block-withedit">
            <div className="profile-img-inner-bk">
              <div className="profile-block-nei">
                <img src= {'images/porfile-2.jpeg'} />
              </div> 
              <div className="profile-block-edit">
                <a href="#"><i className="fas fa-pencil-alt"></i></a>
              </div> 
            </div>
            <div className="profile-blk-content">
              <h4>Rahul Singh</h4>
              <p>+91 999 999 9999</p>
              <p>rahulsingh@gmail.com</p>
            </div>
          </div> 
        </div>
        <div className="user-dashbaord-menu-list">
          <div className="user-progress">
            <p>40% profile Completed</p>
            <ProgressBar now={60} />
          </div>
          <ul className="dashboard-menu-list">
            <li>
              <NavLink to="/Dashboard" activeClassName="active">Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/DashboardBasic" activeClassName="active">Basic Details</NavLink>
            </li>
            <li>
              <NavLink to="/DashboardEdu" activeClassName="active">Education & Career</NavLink>
            </li>
            <li>
              <NavLink to="/DashboardMedia" activeClassName="active">Media (Photos)</NavLink>
            </li>
            <li>
              <NavLink to="/DashboardFamily" activeClassName="active">Family Details</NavLink>
            </li>
            <li>
              <NavLink to="/DashboardBasicPartnerPre" activeClassName="active">Partner Preference</NavLink>
            </li>
            {/* <li>
              <NavLink to="/DashboardVerify" activeClassName="active">Verify (Facebook/Linkedin)</NavLink>
            </li> */}
          </ul>
        </div> 
      </div>
    );
  }
} 
export default DashboardNavigation 