import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class DashboardFamily extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Family Details <NavLink to="/DashboardFamilyEdit">Edit</NavLink></h3>
                  <div className="custom-view-block">
                    <Row>
                      <Col md={6}>
                        <h5><b>Family Type:</b> <span>Joint</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Family Status:</b> <span>Rich</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Family Value:</b> <span>Orthodox</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Father Occupation:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Mother Occupation:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>No. of Brothers:</b> <span>No Brother</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Married Brothers:</b> <span>No Brother</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>No. of Sisters:</b> <span>No Sister</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Married Sisters:</b> <span>No Sister</span></h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default DashboardFamily 