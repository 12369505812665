import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class BlogDetail extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <Row>
              <Col md={8}>
                <div className="full-blog-details-blk">
                  <img src= {'images/blog-1.jpg'} /> 
                  <h3>Lorem ipsum dolor sit amet</h3>
                  <h5><span>04-10-2021</span></h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  incididunt ut labore et dolore magna aliqua</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua  incididunt ut labore et dolore magna aliqua</p>
                  <div className="blog-comments">
                    <h4 className="comments-count">2 Comments</h4>
                    <div id="comment-1" className="comment">
                      <div className="comment-block">
                        <div className="comment-img"><img src= {'images/profile-1.jpg'} /></div>
                        <div className="comment-content">
                          <h5>Georgia Reader</h5>
                          <time datetime="2020-01-01">01 Jan, 2020</time>
                          <p>
                            Et rerum totam nisi. Molestiae vel quam dolorum vel voluptatem et et. Est ad aut sapiente quis molestiae est qui cum soluta.
                            Vero aut rerum vel. Rerum quos laboriosam placeat ex qui. Sint qui facilis et.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div id="comment-2" className="comment">
                      <div className="comment-block">
                        <div className="comment-img"><img src= {'images/profile-1.jpg'} /></div>
                        <div className="comment-content">
                          <h5>Aron Alvarado</h5>
                          <time datetime="2020-01-01">01 Jan, 2020</time>
                          <p>
                            Et rerum totam nisi. Molestiae vel quam dolorum vel voluptatem et et. Est ad aut sapiente quis molestiae est qui cum soluta.
                            Vero aut rerum vel. Rerum quos laboriosam placeat ex qui. Sint qui facilis et.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="reply-form">
                      <h4>Leave a Comment</h4>
                      <p>Your email address will not be published. Required fields are marked * </p>
                      <Form>
                        <Row>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Your Name</Form.Label>
                              <Form.Control type="text" placeholder="Your Name" />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Your Email</Form.Label>
                              <Form.Control type="text" placeholder="Your Email" />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Your Comment</Form.Label>
                              <Form.Control as="textarea" rows={3} placeholder="Your Comment" />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <div className="center-button-block">
                              <Button variant="primary" type="submit">Post Comment</Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="blog-sidebar">
                  <div className="blog-aside">
                    <h3>Recent Blogs</h3>
                    <ul className="recent-blogs-side">
                      <li>
                        <NavLink to="/BlogDetail">
                          <img src= {'images/blog-1.jpg'} /> 
                          <div className="blogside-details">
                            <h4>Lorem ipsum dolor sit amet</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            <h5><span>04-10-2021</span></h5>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/BlogDetail">
                          <img src= {'images/blog-2.jpg'} /> 
                          <div className="blogside-details">
                            <h4>Lorem ipsum dolor sit amet</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            <h5><span>04-10-2021</span></h5>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/BlogDetail">
                          <img src= {'images/blog-1.jpg'} /> 
                          <div className="blogside-details">
                            <h4>Lorem ipsum dolor sit amet</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            <h5><span>04-10-2021</span></h5>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default BlogDetail 