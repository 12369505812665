import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import history from './../history';

export class PreferredMatch extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Preferred Matches</h1>
              <p>Preferred match is the profile show in perticular criteria at its best. Its help you to find out your life partner easily.</p>
            </div>
            <div className="matches-cust-blk">
              <Row>
                <Col md="3">
                  <div className="matches-sidebar-blk">
                    <ul>
                      <li><NavLink to="/OneWayMatch">One Way Matches</NavLink></li>
                      <li><NavLink to="/TwoWayMatch">Two Way Matches</NavLink></li>
                      <li><NavLink to="/BroaderMatch">Broader Matches</NavLink></li>
                      <li><NavLink to="/PreferredMatch">Preferred Matches</NavLink></li>
                      <li><NavLink to="/CustomMatch">Custom Matches</NavLink></li>
                    </ul>
                  </div>
                </Col>
                <Col md="9">
                  <div className="matches-full-blk">
                    <Row className="align-items-center">
                      <Col md={4}>
                        <div className="member-img-block">
                          <img src= {'images/bd-profile-5.jpg'} />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="member-shortdes-block">
                          <h3>Sonia Rai</h3>
                          <p><span>Active</span> <b>&bull;</b> <span>BD12345</span> <b>&bull;</b> <span>Profile created by Self</span></p>
                          <div className="custom-view-block">
                            <Row>
                              <Col md={6}>
                                <h5><b>Age:</b> <span>25</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Height:</b> <span>5'5"</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Gender:</b> <span>Female</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Mother Tongue:</b> <span>Hindi</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Location:</b> <span>Lucknow</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Religion:</b> <span>Hindu</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Marital Status:</b> <span>Single</span></h5>
                              </Col>
                            </Row>
                            <div className="member-main-btn">
                              <Row>
                                <Col md={4}><Button className="btn btn-primary full-btn">Connect Now</Button></Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="matches-full-blk">
                    <Row className="align-items-center">
                      <Col md={4}>
                        <div className="member-img-block">
                          <img src= {'images/bd-profile-4.jpg'} />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="member-shortdes-block">
                          <h3>Sonia Rai</h3>
                          <p><span>Active</span> <b>&bull;</b> <span>BD12345</span> <b>&bull;</b> <span>Profile created by Self</span></p>
                          <div className="custom-view-block">
                            <Row>
                              <Col md={6}>
                                <h5><b>Age:</b> <span>25</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Height:</b> <span>5'5"</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Gender:</b> <span>Female</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Mother Tongue:</b> <span>Hindi</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Location:</b> <span>Lucknow</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Religion:</b> <span>Hindu</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Marital Status:</b> <span>Single</span></h5>
                              </Col>
                            </Row>
                            <div className="member-main-btn">
                              <Row>
                                <Col md={4}><Button className="btn btn-primary full-btn">Connect Now</Button></Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="matches-full-blk">
                    <Row className="align-items-center">
                      <Col md={4}>
                        <div className="member-img-block">
                          <img src= {'images/bd-profile-3.jpg'} />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="member-shortdes-block">
                          <h3>Sonia Rai</h3>
                          <p><span>Active</span> <b>&bull;</b> <span>BD12345</span> <b>&bull;</b> <span>Profile created by Self</span></p>
                          <div className="custom-view-block">
                            <Row>
                              <Col md={6}>
                                <h5><b>Age:</b> <span>25</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Height:</b> <span>5'5"</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Gender:</b> <span>Female</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Mother Tongue:</b> <span>Hindi</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Location:</b> <span>Lucknow</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Religion:</b> <span>Hindu</span></h5>
                              </Col>
                              <Col md={6}>
                                <h5><b>Marital Status:</b> <span>Single</span></h5>
                              </Col>
                            </Row>
                            <div className="member-main-btn">
                              <Row>
                                <Col md={4}><Button className="btn btn-primary full-btn">Connect Now</Button></Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default PreferredMatch 