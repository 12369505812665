import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class DashboardEduEdit extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Education / Profession Info <NavLink to="#">Save</NavLink></h3>
                  <Form>
                    <div className="custom-view-block">
                      <h4>Please enter your details</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Family Type</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Family Type</option>
                                  <option value="Joint">Joint</option>
                                  <option value="Nuclear">Nuclear</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Family Status</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Family Status</option>
                                  <option value="Middle class">Middle class</option>
                                  <option value="Upper middle class">Upper middle class</option>
                                  <option value="Rich">Rich</option>
                                  <option value="Affluent">Affluent</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Family value</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Family value</option>
                                  <option value="Orthodox">Orthodox</option>
                                  <option value="Traditional">Traditional</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Liberal">Liberal</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Occupation</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Occupation</option>
                                  <option value="18">Civil Engineer</option>
                                  <option value="19">Clerical Official</option>
                                  <option value="20">Commercial Pilot</option>
                                  <option value="21">Company Secretary</option>
                                  <option value="22">Computer Professional</option>
                                  <option value="23">Consultant</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Fathers Occupation</Form.Label>
                            <Form.Control type="text" placeholder="Fathers Occupation" />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Mother Occupation</Form.Label>
                            <Form.Control type="text" placeholder="Mother Occupation" />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>No Of Brothers</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select No Of Brothers</option>
                                  <option value="No Brother">No Brother</option>
                                  <option value="1 Brother">1 Brother</option>
                                  <option value="2 Brothers">2 Brothers</option>
                                  <option value="3 Brothers">3 Brothers</option>
                                  <option value="4 Brothers">4 Brothers</option>
                                  <option value="4 + Brothers">4 + Brothers</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>No Of Married Brothers</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select No Of Married Brothers</option>
                                  <option value="No married brother">No married brother</option>
                                  <option value="1 married brother">1 married brother</option>
                                  <option value="2 married brothers">2 married brothers</option>
                                  <option value="3 married brothers">3 married brothers</option>
                                  <option value="4 married brothers">4 married brothers</option>
                                  <option value="4 + married brothers">4 + married brothers</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>No Of Sisters</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select No Of Sisters</option>
                                  <option value="No Sister">No Sister</option>
                                  <option value="1 Sister">1 Sister</option>
                                  <option value="2 Sisters">2 Sisters</option>
                                  <option value="3 Sisters">3 Sisters</option>
                                  <option value="4 Sisters">4 Sisters</option>
                                  <option value="4 + Sisters">4 + Sisters</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>No Of Married Sisters</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select No Of Married Sisters</option>
                                  <option value="No married sister">No married sister</option>
                                  <option value="1 married sister">1 married sister</option>
                                  <option value="2 married sisters">2 married sisters</option>
                                  <option value="3 married sisters">3 married sisters</option>
                                  <option value="4 married sisters">4 married sisters</option>
                                  <option value="4+ married sisters">4+ married sisters</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col md={6}>
                          <div className="center-button-block">
                            <Button variant="primary full-btn" type="submit">Save</Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default DashboardEduEdit 