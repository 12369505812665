import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class Dashboard extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Hi Rahul, Welcome Back</h3>
                  <div className="deshboard-info-block">
                  <Row>
                    <Col md={3}>
                      <div className="showcase-block-dash">
                        <h4><i className="far fa-heart"></i> 38</h4>
                        <p>Shortlisted Members</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="showcase-block-dash">
                        <h4><i className="fas fa-link"></i> 89</h4>
                        <p>Connections</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="showcase-block-dash">
                        <h4><i className="far fa-paper-plane"></i> 24</h4>
                        <p>Interest Send</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="showcase-block-dash">
                        <h4><i className="far fa-eye"></i> 76</h4>
                        <p>My Profile Viewed</p>
                      </div>
                    </Col>
                  </Row>
                  </div>
                  <div className="members-slider-dash">
                    <h4><span>New Matches</span> <a href="#">View All</a></h4>
                    <OwlCarousel items={3} className="owl-theme owl-custom-slider" loop autoplay nav margin={20} >
                      <div className="item">
                        <div className="match-profile-block">
                          <div className="match-profile-img">
                            <img src= {'images/bd-profile-1.jpg'} />
                          </div>
                          <div className="match-profile-cont">
                            <h3>Sonia Ray</h3>
                            <p>23 years, MBA</p>
                            <p>Hindi, Mushlim</p>
                            <p>Lucknow, uttar Pradesh</p>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="match-profile-block">
                          <div className="match-profile-img">
                            <img src= {'images/bd-profile-2.jpg'} />
                          </div>
                          <div className="match-profile-cont">
                            <h3>Sonia Ray</h3>
                            <p>23 years, MBA</p>
                            <p>Hindi, Mushlim</p>
                            <p>Lucknow, uttar Pradesh</p>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="match-profile-block">
                          <div className="match-profile-img">
                            <img src= {'images/bd-profile-3.jpg'} />
                          </div>
                          <div className="match-profile-cont">
                            <h3>Sonia Ray</h3>
                            <p>23 years, MBA</p>
                            <p>Hindi, Mushlim</p>
                            <p>Lucknow, uttar Pradesh</p>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="match-profile-block">
                          <div className="match-profile-img">
                            <img src= {'images/bd-profile-4.jpg'} />
                          </div>
                          <div className="match-profile-cont">
                            <h3>Sonia Ray</h3>
                            <p>23 years, MBA</p>
                            <p>Hindi, Mushlim</p>
                            <p>Lucknow, uttar Pradesh</p>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="match-profile-block">
                          <div className="match-profile-img">
                            <img src= {'images/bd-profile-5.jpg'} />
                          </div>
                          <div className="match-profile-cont">
                            <h3>Sonia Ray</h3>
                            <p>23 years, MBA</p>
                            <p>Hindi, Mushlim</p>
                            <p>Lucknow, uttar Pradesh</p>
                          </div>
                        </div>
                      </div>
                    </OwlCarousel> 
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Dashboard 