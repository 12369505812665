import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

export class About extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="about-section">
          <Container>
            <Row>
              <Col md={6}>
                <div className="about-image-blk">
                  <img src= {'images/about.jpg'} />
                </div>
              </Col>
              <Col md={6}>
                <div className="about-cont-blk">
                  <div className="heading-block">
                    <h4>MORE THAN 20 YEARS OF</h4>
                    <h3>Bringing People <span>Together</span></h3>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <ul>
                    <li>Ut enim ad minim veniam</li>
                    <li>Duis aute irure dolor in reprehenderit </li>
                    <li>Officia deserunt mollit anim id est laborum</li>
                    <li>Uconsectetur adipiscing elit, sed do eiusmod </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <div className="mission-promis-block">
              <div className="heading-block-center heading-block-left">
                <h3>Our <span>Mission & Promise</span></h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div className="ourmission-block">
                <h5>Our Mission</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
              <div className="ourmission-block">
                <h5>Our Vision</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
              <div className="ourmission-block">
                <h5>Our Promise</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
            </div>
          </Container>
        </section>
        <section className="benifit-section">
          <Container>
            <div className="heading-block">
              <h4>MORE THAN 20 YEARS OF</h4>
              <h3>Bringing People <span>Together</span></h3>
            </div>
            <Row>
              <Col md={4}>
                <div className="experience-block">
                  <div className="benifitstript benifit1"></div>
                  <h3>100% Manually Screened Profiles</h3>
                  <p>Search by location, community, profession & more from lakhs of active profiles</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="experience-block">
                  <div className="benifitstript benifit2"></div>
                  <h3>Verification by Personal Visit</h3>
                  <p>Special listing of profiles verified by our agents through personal visits</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="experience-block">
                  <div className="benifitstript benifit3"></div>
                  <h3>Control over Privacy</h3>
                  <p>Restrict unwanted access to contact details & photos/videos</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="upgrade-membership-section">
          <Container>
            <div className="heading-block-center">
              <h3>Upgrade your membership to connect with those you like.</h3>
              <p>Search your perfect life-partner fron millions of trusted & authentic profiles.</p>
            </div>
            <Row>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/phone.png'} />
                    <h4>View Contacts</h4>
                    <p>Access contact number and connect directly on call or via sms.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/message.png'} />
                    <h4>View Email</h4>
                    <p>Get the email id of your selected profile and reach via email</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/chat.png'} />
                    <h4>Chat</h4>
                    <p>Chat instantly with all other online users</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="button-block">
                  <a href="#" className="viewmore-btn">Browse Membership Plans</a>
                  <p>To know more, call us @ +91 999 999 9999 (India)</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default About 