import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import history from './history';
import Home from "./components/Home";
import Login from "./components/Login";
import LoginOTP from "./components/LoginOTP";
import GetOTP from "./components/GetOTP";
import LoginPassword from "./components/LoginPassword";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import SignUp from "./components/SignUp";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Disclaimer from "./components/Disclaimer";
import About from "./components/About";
import Contact from "./components/Contact";
import SignUpProfileDetails from "./components/SignUpProfileDetails";
import SignUpVerify from "./components/SignUpVerify";
import SignUpEducationDetails from "./components/SignUpEducationDetails";
import HomeDashboard from "./components/HomeDashboard";
import Dashboard from "./components/Dashboard";
import DashboardNavigation from "./components/DashboardNavigation";
import DashboardBasic from "./components/DashboardBasic";
import DashboardBasicEdit from "./components/DashboardBasicEdit";
import DashboardEdu from "./components/DashboardEdu";
import DashboardEduEdit from "./components/DashboardEduEdit";
import DashboardFamily from "./components/DashboardFamily";
import DashboardFamilyEdit from "./components/DashboardFamilyEdit";
import DashboardMedia from "./components/DashboardMedia";
import DashboardBasicPartnerPre from "./components/DashboardBasicPartnerPre";
import DashboardBasicPartnerPreEdit from "./components/DashboardBasicPartnerPreEdit";
import Faq from "./components/Faq";
import Testimonial from "./components/Testimonial";
import Search from "./components/Search";
import Membership from "./components/Membership";
import ActiveMember from "./components/ActiveMember";
import HappyStory from "./components/HappyStory";
import HappyStoryDetail from "./components/HappyStoryDetail";
import PostSuccessStory from "./components/PostSuccessStory";
import Blog from "./components/Blog";
import BlogDetail from "./components/BlogDetail";
import Complaint from "./components/Complaint";
import ViewMember from "./components/ViewMember";
import OneWayMatch from "./components/OneWayMatch";
import TwoWayMatch from "./components/TwoWayMatch";
import BroaderMatch from "./components/BroaderMatch";
import PreferredMatch from "./components/PreferredMatch";
import CustomMatch from "./components/CustomMatch";
import Inbox from "./components/Inbox";
import SendMessage from "./components/SendMessage";
import SendSMS from "./components/SendSMS";
import Checkout from "./components/Checkout";
import Notification from "./components/Notification";
import SendList from "./components/SendList";
import ImportantList from "./components/ImportantList";

export class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/Login" exact component={Login} />
                    <Route path="/LoginOTP" exact component={LoginOTP} />
                    <Route path="/GetOTP" exact component={GetOTP} />
                    <Route path="/LoginPassword" exact component={LoginPassword} />
                    <Route path="/ForgotPassword" exact component={ForgotPassword} />
                    <Route path="/ResetPassword" exact component={ResetPassword} />
                    <Route path="/SignUp" exact component={SignUp} />
                    <Route path="/Terms" exact component={Terms} />
                    <Route path="/Privacy" exact component={Privacy} />
                    <Route path="/Disclaimer" exact component={Disclaimer} />
                    <Route path="/About" exact component={About} />
                    <Route path="/Contact" exact component={Contact} />
                    <Route path="/SignUpProfileDetails" exact component={SignUpProfileDetails} />
                    <Route path="/SignUpVerify" exact component={SignUpVerify} />
                    <Route path="/SignUpEducationDetails" exact component={SignUpEducationDetails} />
                    <Route path="/HomeDashboard" exact component={HomeDashboard} />
                    <Route path="/Dashboard" exact component={Dashboard} />
                    <Route path="/DashboardNavigation" exact component={DashboardNavigation} />
                    <Route path="/DashboardBasic" exact component={DashboardBasic} />
                    <Route path="/DashboardBasicEdit" exact component={DashboardBasicEdit} />
                    <Route path="/DashboardEdu" exact component={DashboardEdu} />
                    <Route path="/DashboardEduEdit" exact component={DashboardEduEdit} />
                    <Route path="/DashboardFamily" exact component={DashboardFamily} />
                    <Route path="/DashboardFamilyEdit" exact component={DashboardFamilyEdit} />
                    <Route path="/DashboardMedia" exact component={DashboardMedia} />
                    <Route path="/DashboardBasicPartnerPre" exact component={DashboardBasicPartnerPre} />
                    <Route path="/DashboardBasicPartnerPreEdit" exact component={DashboardBasicPartnerPreEdit} />
                    <Route path="/Faq" exact component={Faq} />
                    <Route path="/Testimonial" exact component={Testimonial} />
                    <Route path="/Search" exact component={Search} />
                    <Route path="/Membership" exact component={Membership} />
                    <Route path="/ActiveMember" exact component={ActiveMember} />
                    <Route path="/HappyStory" exact component={HappyStory} />
                    <Route path="/HappyStoryDetail" exact component={HappyStoryDetail} />
                    <Route path="/PostSuccessStory" exact component={PostSuccessStory} />
                    <Route path="/Blog" exact component={Blog} />
                    <Route path="/BlogDetail" exact component={BlogDetail} />
                    <Route path="/Complaint" exact component={Complaint} />
                    <Route path="/ViewMember" exact component={ViewMember} />
                    <Route path="/OneWayMatch" exact component={OneWayMatch} />
                    <Route path="/TwoWayMatch" exact component={TwoWayMatch} />
                    <Route path="/BroaderMatch" exact component={BroaderMatch} />
                    <Route path="/PreferredMatch" exact component={PreferredMatch} />
                    <Route path="/CustomMatch" exact component={CustomMatch} />
                    <Route path="/Inbox" exact component={Inbox} />
                    <Route path="/SendMessage" exact component={SendMessage} />
                    <Route path="/SendSMS" exact component={SendSMS} />
                    <Route path="/Checkout" exact component={Checkout} />
                    <Route path="/Notification" exact component={Notification} />
                    <Route path="/SendList" exact component={SendList} />
                    <Route path="/ImportantList" exact component={ImportantList} />
                </Switch>
            </Router>
        )
    }
}
export default Routes 