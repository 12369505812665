import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

export class Footer extends Component {  

  render() {
    return (
      <footer>
        <div className="topfoot-section">
          <Container>
            <Row>
              <Col md={4}>
                <div className="topfoot-blk">
                  <h4>By Mother Tongue</h4>
                  <ul>
                    <li><NavLink to="#">Bengali</NavLink></li>
                    <li><NavLink to="#">Gujarati</NavLink></li>
                    <li><NavLink to="#">Kannada</NavLink></li>
                    <li><NavLink to="#">Malayalam</NavLink></li>
                    <li><NavLink to="#">Marathi</NavLink></li>
                    <li><NavLink to="#">Odia</NavLink></li>
                    <li><NavLink to="#">Punjabi</NavLink></li>
                    <li><NavLink to="#">Tamil</NavLink></li>
                    <li><NavLink to="#">Telugu</NavLink></li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div className="topfoot-blk">
                  <h4>By Religion</h4>
                  <ul>
                    <li><NavLink to="#">Buddhist</NavLink></li>
                    <li><NavLink to="#">Christian</NavLink></li>
                    <li><NavLink to="#">Jain</NavLink></li>
                    <li><NavLink to="#">Muslim</NavLink></li>
                    <li><NavLink to="#">Parsi</NavLink></li>
                    <li><NavLink to="#">Sikh</NavLink></li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div className="topfoot-blk">
                  <h4>By Community</h4>
                  <ul>
                    <li><NavLink to="#">Brahmin</NavLink></li>
                    <li><NavLink to="#">Ezhava</NavLink></li>
                    <li><NavLink to="#">Kamma</NavLink></li>
                    <li><NavLink to="#">Kapu</NavLink></li>
                    <li><NavLink to="#">Maratha</NavLink></li>
                    <li><NavLink to="#">Nadar</NavLink></li>
                    <li><NavLink to="#">Nair</NavLink></li>
                    <li><NavLink to="#">Rajput</NavLink></li>
                    <li><NavLink to="#">Reddy</NavLink></li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="foot-menus-links-block">
          <Container>
            <Row>
              <Col md={3}>
                <h4 className="foot-heading">General Links</h4>
                <Row>
                  <Col md={6}>
                    <ul className="foot-cust-menu">
                      <li><NavLink to="/">Home</NavLink></li>
                      <li><NavLink to="/Faq">FAQ's</NavLink></li>
                      <li><NavLink to="/About">About Us</NavLink></li>
                      <li><NavLink to="/Testimonial">Testimonials</NavLink></li>
                      <li><NavLink to="/Blog">Blog</NavLink></li>
                      <li><NavLink to="/Privacy">Privacy Policy</NavLink></li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="foot-cust-menu">
                      <li><NavLink to="/Contact">Contact Us</NavLink></li>
                      <li><NavLink to="/Membership">Membership</NavLink></li>
                      <li><NavLink to="/Complaint">Complaint</NavLink></li>
                      <li><NavLink to="/Terms">Terms of Use</NavLink></li>
                      <li><NavLink to="/Disclaimer">Disclaimer</NavLink></li>
                      <li><NavLink to="#">Sitemap</NavLink></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <h4 className="foot-heading">Caste Matrimony</h4>
                <Row>
                  <Col md={6}>
                    <ul className="foot-cust-menu">
                      <li><NavLink to="#">Brahmin</NavLink></li>
                      <li><NavLink to="#">Rajput</NavLink></li>
                      <li><NavLink to="#">Chavara</NavLink></li>
                      <li><NavLink to="#">Ezhava</NavLink></li>
                      <li><NavLink to="#">Vishwakarma</NavLink></li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="foot-cust-menu">
                      <li><NavLink to="#">Maratha</NavLink></li>
                      <li><NavLink to="#">Reddy</NavLink></li>
                      <li><NavLink to="#">Kayastha</NavLink></li>
                      <li><NavLink to="#">Vanniyar</NavLink></li>
                      <li><NavLink to="#">Lyer</NavLink></li>
                      <li><NavLink to="#">Nair</NavLink></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <h4 className="foot-heading">Community Matrimony</h4>
                <Row>
                  <Col md={6}>
                    <ul className="foot-cust-menu">
                      <li><NavLink to="#">Tamil</NavLink></li>
                      <li><NavLink to="#">Bengali</NavLink></li>
                      <li><NavLink to="#">Marathi</NavLink></li>
                      <li><NavLink to="#">Punjabi</NavLink></li>
                      <li><NavLink to="#">Gujarati</NavLink></li>
                      <li><NavLink to="#">Sindhi</NavLink></li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="foot-cust-menu">
                      <li><NavLink to="#">Telugu</NavLink></li>
                      <li><NavLink to="#">Oriya</NavLink></li>
                      <li><NavLink to="#">Kannada</NavLink></li>
                      <li><NavLink to="#">Hindi</NavLink></li>
                      <li><NavLink to="#">Urdu</NavLink></li>
                      <li><NavLink to="#">Malayalam</NavLink></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <Row>
                  <Col md={6}>
                    <h4 className="foot-heading">State Matrimony</h4>
                    <ul className="foot-cust-menu">
                      <li><NavLink to="#">Kerala</NavLink></li>
                      <li><NavLink to="#">Uttrakhand</NavLink></li>
                      <li><NavLink to="#">Delhi</NavLink></li>
                      <li><NavLink to="#">Punjab</NavLink></li>
                      <li><NavLink to="#">Gujarat</NavLink></li>
                      <li><NavLink to="#">orissa</NavLink></li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <h4 className="foot-heading">City Matrimony</h4>
                    <ul className="foot-cust-menu">
                      <li><NavLink to="#">Chennai</NavLink></li>
                      <li><NavLink to="#">Bangalore</NavLink></li>
                      <li><NavLink to="#">Mumbai</NavLink></li>
                      <li><NavLink to="#">Kolkata</NavLink></li>
                      <li><NavLink to="#">Kollam</NavLink></li>
                      <li><NavLink to="#">Lucknow</NavLink></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright-footer">
          <Container>
            <Row>
              <Col md={6}>
                <div className="copyright-blk">
                  Copyright 2021 Bin Dahej | All Rights Reserved.
                </div>
              </Col>
              <Col md={6}>
                <div className="copyright-blk text-right">
                  Web Design by <NavLink to="#">Web Mingo IT Solutions Pvt. Ltd.</NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
} 
export default Footer 