import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class DashboardBasicPartnerPre extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Partner Preference <NavLink to="/DashboardBasicPartnerPreEdit">Edit</NavLink></h3>
                  <div className="custom-view-block">
                    <h4>Basic Preference</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Age:</b> <span>20 Years to 25 Years</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Height:</b> <span>5'0" to 5'10"</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Diet:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Smoking:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Drinking:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Physical Status:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Looking For:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                    </Row>
                  </div>

                  <div className="custom-view-block">
                    <h4>Religion Information</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Religion:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Caste:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Mother Tongue:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Manglik:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Star:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                    </Row>
                  </div>

                  <div className="custom-view-block">
                    <h4>Location</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Country Living In:</b> <span>India</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>State Living In:</b> <span>Uttar Pradesh</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>City Living In:</b> <span>Lucknow</span></h5>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="custom-view-block">
                    <h4>Education & Occupation Preference</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Education:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Occupation:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Annual Income:</b> <span>Doesn't Matter</span></h5>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="custom-view-block">
                    <h4>Partner Expectation</h4>
                    <Row>
                      <Col md={12}>
                        <h5><b>Partner Expectation:</b> <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default DashboardBasicPartnerPre 