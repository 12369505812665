import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class ForgotPassword extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="login-signup-section">
            <Container>
                <Row className="justify-content-center">
                    <Col md="4">
                        <div className="login-inner-block">
                            <div className="heading-title-blk">
                                <h4>Forgot your password?</h4>
                                <p>Don'tworry! Just fill in your email and we'll send you a link to reset your password.</p>
                            </div>
                            <div className="form-block">
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control type="email" placeholder="Enter Email ID" />
                                    </Form.Group>
                                    <Button variant="primary full-btn" type="submit" onClick={() => history.push('/ResetPassword')}>Reset Password</Button>
                                    <div className="login-bottom-blk">
                                        <h5>Remember password? <NavLink to="/Login">Login</NavLink></h5>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default ForgotPassword 