import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class DashboardFamily extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Your Photos</h3>
                  <div className="custom-view-block">
                    <Row>
                      <Col md={6}>
                        <div className="profile-imgpre-blk">
                          <Form>
                            <div className="previewprofile-blk">
                              <img src={'images/porfile-2.jpeg'} />
                            </div>
                            <div className="upload-profileimg-blk">
                              <Form.Group controlId="formFile" className="upload-file-blk">
                                <Form.Control type="file"/>
                              </Form.Group>
                              <Button variant="primary" type="submit">Upload</Button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="profile-setting-blk">
                          <h4>Setting</h4>
                          <Form>
                            <Form.Group className="mb-3">
                              <Form.Label>Photo privacy Status</Form.Label>
                              <Form.Control as="select" className="form-select">
                                <option value="">Select Privacy Status</option>
                                <option value="Visible to all">Visible to all</option>
                                <option value="Visible to Paid Member">Visible to Paid Member</option>
                                <option value="Only Me">Only Me</option>
                              </Form.Control>
                            </Form.Group>
                            <Button variant="primary" type="submit">Save Setting</Button>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="uploadmore-photos">
                    <h3>Other Photos</h3>
                    <Form>
                      <div className="upload-profileimg-blk">
                        <Form.Group controlId="formFile" className="upload-file-blk">
                          <Form.Control type="file"/>
                        </Form.Group>
                        <Button variant="primary" type="submit">Upload</Button>
                      </div>
                    </Form>
                    <Row>
                      <Col md={4}>
                        <div className="preview-other-profile-blk">
                          <img src={'images/porfile-2.jpeg'} />
                          <a href="#" className="trash-photo-btn"><i className="far fa-trash-alt"></i></a>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="preview-other-profile-blk">
                          <img src={'images/porfile-2.jpeg'} />
                          <a href="#" className="trash-photo-btn"><i className="far fa-trash-alt"></i></a>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="preview-other-profile-blk">
                          <img src={'images/porfile-2.jpeg'} />
                          <a href="#" className="trash-photo-btn"><i className="far fa-trash-alt"></i></a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default DashboardFamily 