import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class DashboardBasic extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Basic Details <NavLink to="/DashboardBasicEdit">Edit</NavLink></h3>
                  <div className="custom-view-block">
                    <Row>
                      <Col md={6}>
                        <h5><b>Name:</b> <span>Rahul Singh</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Email:</b> <span>rahulsingh@gmail.com</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Mobile Number:</b> <span>+91 999 999 9999</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Gender:</b> <span>Male</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Mother Tongue:</b> <span>Hindi</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Profile Created By:</b> <span>Self</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Date of Birth:</b> <span>01/12/2021</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Marital Status:</b> <span>Single</span></h5>
                      </Col>
                      <Col md={12}>
                        <h5><b>About Us:</b> <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></h5>
                      </Col>
                    </Row>
                  </div>

                  <div className="custom-view-block">
                    <h4>Religion Information</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Religion:</b> <span>Hindu</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Caste:</b> <span>Hindu</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Sub Caste:</b> <span>NA</span></h5>
                      </Col>
                    </Row>
                  </div>

                  <div className="custom-view-block">
                    <h4>Location</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Country Living In:</b> <span>India</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>State Living In:</b> <span>Uttar Pradesh</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>City Living In:</b> <span>Lucknow</span></h5>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="custom-view-block">
                    <h4>Habits & Hobbies</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Diet Habits:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Vegetarian:</b> <span>NO</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Smoke Habits:</b> <span>No</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Hobby:</b> <span>NO</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Language Known:</b> <span>Hindi, English</span></h5>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="custom-view-block">
                    <h4>Physical Attribute</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Height:</b> <span>5'8"</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Weight:</b> <span>70 KG</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Body type:</b> <span>Slim</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Complexion:</b> <span>Fair</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Physical Status:</b> <span>NA</span></h5>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="custom-view-block">
                    <h4>Horoscope Information</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Have Dosh?:</b> <span>No</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Dosh Type:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Moonsign(Raasi):</b> <span>Mesh(Aries)</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Star:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Birth Date:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Birth Place:</b> <span>NA</span></h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default DashboardBasic 