import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Button, Modal, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import history from './../history';

export class HeaderLogin extends Component {  
  render() {
    return (
        <div className="header-section">
            <div className="top-header">
            <Container>
                <Row>
                <Col md={6}>
                    <p className="welcome-text">Welcome to Bin Dahej</p>
                </Col>
                <Col md={6}>
                    <ul className="top-menu-list">
                    <li className="hide-on-mobile"><a href="tel:+919999999999">Helpline +91 999 999 9990</a></li>
                    <li><NavLink to="/Contact">Contact Us</NavLink></li>
                    <li><NavLink to="#">Support</NavLink></li>
                    </ul>
                </Col>
                </Row>
            </Container>
            </div>
            <Navbar expand="lg">
            <Container>
                <NavLink className="navbar-brand" to="/HomeDashboard"><img src= {'images/logo.png'} /></NavLink>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav className="ml-auto">
                    <NavLink className="nav-link" to="/HomeDashboard">Home</NavLink>
                    <NavDropdown title="Matches" id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={() => history.push('/OneWayMatch')}>One Way Matches</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/TwoWayMatch')}>Two Way Matches</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/BroaderMatch')}>Broader Matches</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/PreferredMatch')}>Preferred Matches</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/CustomMatch')}>Custom Matches</NavDropdown.Item>
                    </NavDropdown>
                    <NavLink className="nav-link" to="/Inbox">Inbox</NavLink>
                    <NavLink className="nav-link" to="/Search">Search</NavLink>
                    <NavLink className="nav-link" to="/DashboardBasic">My Profile</NavLink>
                    <Nav.Link href="/Inbox" className="chat-header-block"><i className="far fa-comments"></i></Nav.Link>
                    <Nav.Link href="/Notification" className="user-image notification-header"><i className="far fa-bell"></i></Nav.Link>
                    {/* <NavDropdown className="user-dropdown-block"
                    title={
                        <div className="user-image notification-header">
                            <i className="far fa-bell"></i>
                        </div>
                    } 
                    id="basic-nav-dropdown">
                        <NavDropdown.Item href="#">One Way Matches</NavDropdown.Item>
                        <NavDropdown.Item href="#">Two Way Matches</NavDropdown.Item>
                        <NavDropdown.Item href="#">Broader Matches</NavDropdown.Item>
                        <NavDropdown.Item href="#">Preferred Matches</NavDropdown.Item>
                        <NavDropdown.Item href="#">Custom Matches</NavDropdown.Item>
                    </NavDropdown> */}
                    <NavDropdown className="user-dropdown-block"
                    title={
                        <div className="user-image">
                            <img src={"images/user.jpg"} alt="user pic" />
                        </div>
                    } 
                    id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={() => history.push('/Dashboard')}>Dashboard</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/DashboardBasic')}>Basic Details</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/DashboardEdu')}>Education & Career</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/DashboardMedia')}>Media (Photos)</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/DashboardFamily')}>Family Details</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/DashboardBasicPartnerPre')}>Partner Preference</NavDropdown.Item>
                        {/* <NavDropdown.Item href="/DashboardVerifydsfdsfdsf">Verify (Facebook/Linkedin)</NavDropdown.Item> */}
                    </NavDropdown>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
        </div>
    );
  }
} 
export default HeaderLogin 