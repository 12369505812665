import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import history from './../history';

export class Checkout extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Checkout</h1>
              <p>Pay fast and securly with our multiple payment option.</p>
            </div>
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="checkout-blk">
                  <div className="checkout-table-blk">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Plan Name</th>
                          <th>Duration</th>
                          <th className="text-right">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Gold 3 Month</td>
                          <td>3 Month</td>
                          <td className="text-right">₹ 2999</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th></th>
                          <th className="text-right">Tax/GST:</th>
                          <th className="text-right">₹ 200</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th className="text-right">Total:</th>
                          <th className="text-right">₹ 3299</th>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                  <div className="checkout-button-blk">
                    <Button variant="primary">Pay Now</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Checkout 