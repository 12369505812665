import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class HappyStoryDetail extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Happy Marriages</h1>
              <p>Check it out our success stories who found their life partner here.</p>
            </div>
            <div className="success-stories-blk">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="success-heading">
                    <h3>Success Story</h3>
                    <p>Some of our happily married couples story </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="write-story-btn-blk">
                    <NavLink to="/PostSuccessStory" className="btn btn-primary">Post Your Success Story</NavLink>
                  </div>
                </Col>
              </Row>
              <div className="success-fullblk">
                <Row className="align-items-center">
                  <Col md={4}>
                    <div className="successstory-img">
                      <img src= {'images/couple-1.jpg'} /> 
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="successstory-contblk">
                      <h4>Prem and Kiran</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                      <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default HappyStoryDetail 