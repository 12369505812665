import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class GetOTP extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="login-signup-section">
            <Container>
                <Row className="justify-content-center">
                    <Col md="4">
                        <div className="login-inner-block">
                            <div className="heading-title-blk">
                                <h4>Please enter your verification code</h4>
                                <p>We have send a verification code to your mobile number.</p>
                            </div>
                            <div className="form-block">
                                <Form>
                                    <Form.Group className="mb-3">
                                    <div id="divOuter">
                                        <div id="divInner">
                                            <input id="partitioned" type="text" maxlength="4" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  onKeyPress="if(this.value.length==4) return false;"/>
                                        </div>
                                    </div>
                                    </Form.Group>
                                    <Button variant="primary full-btn" type="submit">Get OTP</Button>
                                    <div className="login-bottom-blk">
                                        <h5>Didn't Received OTP <NavLink to="#">Resend</NavLink></h5>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default GetOTP 