import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class LoginOTP extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="login-signup-section">
            <Container>
                <Row className="justify-content-center">
                    <Col md="4">
                        <div className="login-inner-block">
                            <div className="heading-title-blk">
                                <Button variant="secondary full-btn" onClick={() => history.push('/Login')}>Login using Email Id</Button>
                                <div className="or-block"><span>OR</span></div>
                                <h3>Login with Mobile Number</h3>
                            </div>
                            <div className="form-block">
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control type="email" placeholder="You will get OTP on this number" />
                                    </Form.Group>
                                    <Button variant="primary full-btn" type="submit" onClick={() => history.push('/GetOTP')}>Get OTP</Button>
                                    <div className="login-bottom-blk">
                                        <h5>Login with <NavLink to="/LoginPassword">Password</NavLink></h5>
                                        <p>By Logging In, you agree the following <NavLink to="/Terms">Terms & Conditions</NavLink> and the <NavLink to="/Privacy">Privacy Policy</NavLink></p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default LoginOTP 