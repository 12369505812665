import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './Header';
import Footer from './Footer';

export class About extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="testimonial-section">
          <Container>
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="testimonial-block">
                  <OwlCarousel items={1} className="owl-theme owl-custom-slider" loop autoplay nav margin={20} >
                    <div className="item">
                      <div className="testi-blk">
                        <div className="testi-cont">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                          est laborum.
                        </div>
                        <div className="testi-img-auth">
                          <div className="testi-img"><img src= {'images/couple-2.jpg'} /></div>
                          <div className="testi-auth">
                            <h4>Alen Boundry</h4>
                            <p>Customer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testi-blk">
                        <div className="testi-cont">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                          est laborum.
                        </div>
                        <div className="testi-img-auth">
                          <div className="testi-img"><img src= {'images/couple-2.jpg'} /></div>
                          <div className="testi-auth">
                            <h4>Alen Boundry</h4>
                            <p>Customer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="upgrade-membership-section">
          <Container>
            <div className="heading-block-center">
              <h3>Upgrade your membership to connect with those you like.</h3>
              <p>Search your perfect life-partner fron millions of trusted & authentic profiles.</p>
            </div>
            <Row>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/phone.png'} />
                    <h4>View Contacts</h4>
                    <p>Access contact number and connect directly on call or via sms.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/message.png'} />
                    <h4>View Email</h4>
                    <p>Get the email id of your selected profile and reach via email</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/chat.png'} />
                    <h4>Chat</h4>
                    <p>Chat instantly with all other online users</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="button-block">
                  <a href="#" className="viewmore-btn">Browse Membership Plans</a>
                  <p>To know more, call us @ +91 999 999 9999 (India)</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default About 