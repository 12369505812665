import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import history from './../history';

export class SendSMS extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Message - Compose</h1>
              <p>Send Message to member from here.</p>
            </div>
            <div className="matches-cust-blk">
              <Row>
                <Col md="3">
                  <div className="sms-button-block">
                    <Button variant="primary" className="full-btn" onClick={ () => history.push('/SendMessage')}>Send Message</Button>
                    <Button variant="primary" className="full-btn" onClick={ () => history.push('/SendSMS')}>Send SMS</Button>
                  </div>
                  <div className="inbox-sidebar-blk">
                    <ul>
                      <li><NavLink to="/Inbox">Inbox <span>4</span></NavLink></li>
                      <li><NavLink to="/SendList">Send <span>3</span></NavLink></li>
                      <li><NavLink to="/ImportantList">Inportant <span>3</span></NavLink></li>
                    </ul>
                  </div>
                </Col>
                <Col md="9">
                  <div className="inbox-full-blk">
                    <div className="inbox-send-blk">
                      <Form>
                        <p>To,</p>
                        <Row className="justify-content-between">
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" placeholder="Member Id" />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control as="textarea" rows={3} placeholder="Write Message" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col md={6}>
                            <div className="center-button-block">
                              <Button variant="primary full-btn" type="submit">Send SMS</Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <section className="upgrade-membership-section">
          <Container>
            <div className="heading-block-center">
              <h3>Upgrade your membership to connect with those you like.</h3>
              <p>Search your perfect life-partner fron millions of trusted & authentic profiles.</p>
            </div>
            <Row>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/phone.png'} />
                    <h4>View Contacts</h4>
                    <p>Access contact number and connect directly on call or via sms.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/message.png'} />
                    <h4>View Email</h4>
                    <p>Get the email id of your selected profile and reach via email</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/chat.png'} />
                    <h4>Chat</h4>
                    <p>Chat instantly with all other online users</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="button-block">
                  <a href="#" className="viewmore-btn">Browse Membership Plans</a>
                  <p>To know more, call us @ +91 999 999 9999 (India)</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default SendSMS 