import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

export class Contact extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="contact-section">
          <Container>
            <Row>
              <Col md={5}>
                <div className="contact-info-blk">
                  <h4>Contact Info</h4>
                  <div className="branches">
                    <h5>Main Branch</h5>
                    <p><i className="fas fa-map-marker-alt"></i> <b>1st Floor, Dayal Chamber, 3-Way Road, Ram Mohan Rai Marg, Hazratganj, Lucknow, Uttar Pradesh 226001</b></p>
                    <p><i className="fas fa-phone-alt"></i> <b>+91 999 999 9999</b></p>
                    <p><i className="far fa-envelope"></i> <b>bindahej@gmail.com</b></p>
                  </div>
                  <div className="branches">
                    <h5>Sub Branch</h5>
                    <p><i className="fas fa-map-marker-alt"></i> <b>1st Floor, Dayal Chamber, 3-Way Road, Ram Mohan Rai Marg, Hazratganj, Lucknow, Uttar Pradesh 226001</b></p>
                    <p><i className="fas fa-phone-alt"></i> <b>+91 999 999 9999</b></p>
                    <p><i className="far fa-envelope"></i> <b>bindahej@gmail.com</b></p>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <div className="contact-form-blk">
                  <h4>Ask query or give us feedback</h4>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Your Name</Form.Label>
                          <Form.Control type="text" placeholder="Your Name" />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Your Email</Form.Label>
                          <Form.Control type="text" placeholder="Your Email" />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Your Mobile Number</Form.Label>
                          <Form.Control type="text" placeholder="Your Mobile Number" />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Subject</Form.Label>
                          <Form.Control type="text" placeholder="Subject" />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Your Message</Form.Label>
                          <Form.Control as="textarea" rows={3} placeholder="Your Message" />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Button variant="primary full-btn" type="submit">Submit</Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
              <Col md={12}>
                <div className="map-block">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14237.953662813528!2d80.9540497!3d26.8562193!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf3bbf4c63ecd82a9!2sWeb%20Mingo%20IT%20Solutions%20Pvt.%20Ltd.%20-%20Website%20Designing%20%26%20Digital%20Marketing%20Company!5e0!3m2!1sen!2sin!4v1633350910688!5m2!1sen!2sin" width="100%" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="upgrade-membership-section">
          <Container>
            <div className="heading-block-center">
              <h3>Upgrade your membership to connect with those you like.</h3>
              <p>Search your perfect life-partner fron millions of trusted & authentic profiles.</p>
            </div>
            <Row>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/phone.png'} />
                    <h4>View Contacts</h4>
                    <p>Access contact number and connect directly on call or via sms.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/message.png'} />
                    <h4>View Email</h4>
                    <p>Get the email id of your selected profile and reach via email</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/chat.png'} />
                    <h4>Chat</h4>
                    <p>Chat instantly with all other online users</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="button-block">
                  <a href="#" className="viewmore-btn">Browse Membership Plans</a>
                  <p>To know more, call us @ +91 999 999 9999 (India)</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Contact 