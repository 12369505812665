import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class HappyStory extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Happy Marriages</h1>
              <p>Check it out our success stories who found their life partner here.</p>
            </div>
            <div className="success-stories-blk">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="success-heading">
                    <h3>Success Story</h3>
                    <p>Some of our happily married couples story </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="write-story-btn-blk">
                    <NavLink to="/PostSuccessStory" className="btn btn-primary">Post Your Success Story</NavLink>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="successstory-blk">
                    <img src= {'images/couple-1.jpg'} /> 
                    <div className="successstory-cont">
                      <h4>Prem and Kiran</h4>
                      <p>Prem and Kiran. Well we met on bindahej.com. I was in search of an educated,decent person with well family background. One day we got a call from my father-in-law and their approach made us ...</p>
                    </div>
                    <NavLink to="/HappyStoryDetail" className="readmore-btn">Read More</NavLink>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="successstory-blk">
                    <img src= {'images/couple-2.jpg'} /> 
                    <div className="successstory-cont">
                      <h4>Prem and Kiran</h4>
                      <p>Prem and Kiran. Well we met on bindahej.com. I was in search of an educated,decent person with well family background. One day we got a call from my father-in-law and their approach made us ...</p>
                    </div>
                    <NavLink to="/HappyStoryDetail" className="readmore-btn">Read More</NavLink>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="successstory-blk">
                    <img src= {'images/couple-3.jpg'} /> 
                    <div className="successstory-cont">
                      <h4>Prem and Kiran</h4>
                      <p>Prem and Kiran. Well we met on bindahej.com. I was in search of an educated,decent person with well family background. One day we got a call from my father-in-law and their approach made us ...</p>
                    </div>
                    <NavLink to="/HappyStoryDetail" className="readmore-btn">Read More</NavLink>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default HappyStory 