import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class Blog extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Our Blogs</h1>
              <p>View our latest blogs.</p>
            </div>
            <div className="success-stories-blk">
              <div className="success-fullblk blog-main-block">
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="successstory-img">
                      <img src= {'images/blog-1.jpg'} /> 
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="successstory-contblk">
                      <h4><NavLink to="/BlogDetail">Lorem ipsum dolor sit amet</NavLink></h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua....</p>
                      <NavLink to="/BlogDetail" className="btn btn-primary">Read More</NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="success-fullblk blog-main-block">
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="successstory-img">
                      <img src= {'images/blog-2.jpg'} /> 
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="successstory-contblk">
                      <h4><NavLink to="/BlogDetail">Lorem ipsum dolor sit amet</NavLink></h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua....</p>
                      <NavLink to="/BlogDetail" className="btn btn-primary">Read More</NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="success-fullblk blog-main-block">
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="successstory-img">
                      <img src= {'images/blog-1.jpg'} /> 
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="successstory-contblk">
                      <h4><NavLink to="/BlogDetail">Lorem ipsum dolor sit amet</NavLink></h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua....</p>
                      <NavLink to="/BlogDetail" className="btn btn-primary">Read More</NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Blog 