import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class Complaint extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Complaint</h1>
              <p>Fill the below form and send us your query.</p>
            </div>
            <div className="complaint-blk">
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Your Name</Form.Label>
                      <Form.Control type="text" placeholder="Your Name" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Your Id</Form.Label>
                      <Form.Control type="text" placeholder="Your Id" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Your Email</Form.Label>
                      <Form.Control type="text" placeholder="Your Email" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Your Mobile Number</Form.Label>
                      <Form.Control type="text" placeholder="Your Mobile Number" />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Your Query</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Your Query" />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Button variant="primary full-btn" type="submit">Submit</Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Complaint 