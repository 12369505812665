import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class DashboardEduEdit extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Education / Profession Info <NavLink to="#">Save</NavLink></h3>
                  <Form>
                    <div className="custom-view-block">
                      <h4>Please enter your details</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Highest Education</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Highest Education</option>
                                  <option value="8">BCA</option>
                                  <option value="10">B Plan</option>
                                  <option value="9">B Arch</option>
                                  <option value="11">BE</option>
                                  <option value="12">B Tech</option>
                                  <option value="13">BSc Computer Science</option>
                                  <option value="14">BSc IT</option>
                                  <option value="15">B Phil</option>
                                  <option value="16">B Com</option>
                                  <option value="17">BA</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Additional Degree</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Additional Degree</option>
                                  <option value="8" selected="selected">BCA</option>
                                  <option value="10">B Plan</option>
                                  <option value="9">B Arch</option>
                                  <option value="11">BE</option>
                                  <option value="12">B Tech</option>
                                  <option value="13">BSc Computer Science</option>
                                  <option value="14">BSc IT</option>
                                  <option value="15">B Phil</option>
                                  <option value="16">B Com</option>
                                  <option value="17">BA</option>
                                  <option value="18">BFA</option>
                                  <option value="19">BLIS</option>
                                  <option value="20">BSW</option>
                                  <option value="21">BMM (MASS MEDIA)</option>
                                  <option value="22">BEd</option>
                                  <option value="23">MEd</option>
                                  <option value="24">BHM</option>
                                  <option value="25">BBA</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Employed in</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Employed in</option>
                                    <option value="Private">Private</option>
                                    <option value="Government">Government</option>
                                    <option value="Business">Business</option>
                                    <option value="Defence">Defence</option>
                                    <option value="Self Employed">Self Employed</option>
																		<option value="Not Working">Not Working</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Occupation</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Occupation</option>
                                  <option value="18">Civil Engineer</option>
                                  <option value="19">Clerical Official</option>
                                  <option value="20">Commercial Pilot</option>
                                  <option value="21">Company Secretary</option>
                                  <option value="22">Computer Professional</option>
                                  <option value="23">Consultant</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Annual Income</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Annual Income</option>
                                  <option value="Rs 1,00,000 - 2,00,000">Rs 1,00,000 - 2,00,000</option>
                                  <option value="Rs 2,00,000 - 4,00,000">Rs 2,00,000 - 4,00,000</option>
                                  <option value="Rs 4,00,000 - 6,00,000">Rs 4,00,000 - 6,00,000</option>
                                  <option value="Rs 6,00,000 - 8,00,000">Rs 6,00,000 - 8,00,000</option>
                                  <option value="Rs 8,00,000 - 10,00,000">Rs 8,00,000 - 10,00,000</option>
                                  <option value="Rs 10,00,000 - 12,00,000">Rs 10,00,000 - 12,00,000</option>
                                  <option value="Rs 12,00,000 - 14,00,000">Rs 12,00,000 - 14,00,000</option>
                                  <option value="Rs 14,00,000 - 16,00,000">Rs 14,00,000 - 16,00,000</option>
                                  <option value="Rs 16,00,000 - 18,00,000">Rs 16,00,000 - 18,00,000</option>
                                  <option value="Rs 18,00,000 - 20,00,000">Rs 18,00,000 - 20,00,000</option>
                                  <option value="Rs 20,00,000 - 22,00,000">Rs 20,00,000 - 22,00,000</option>
                                  <option value="Not Working">Not Working</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col md={6}>
                          <div className="center-button-block">
                            <Button variant="primary full-btn" type="submit">Save</Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default DashboardEduEdit 