import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import history from './../history';

export class Checkout extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Notification</h1>
              <p>See all notification here</p>
            </div>
            <Row className="justify-content-center">
              <Col md={10}>
                <div className="notificationtable-blk">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th width="30">#</th>
                        <th width="130">Date & Time</th>
                        <th>Notification</th>
                        <th width="40">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>12-10-2021, 12:33 PM</td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                        <td>
                          <div className="action-button-blk">
                            <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>12-10-2021, 12:33 PM</td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                        <td>
                          <div className="action-button-blk">
                            <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>12-10-2021, 12:33 PM</td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                        <td>
                          <div className="action-button-blk">
                            <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>12-10-2021, 12:33 PM</td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                        <td>
                          <div className="action-button-blk">
                            <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>12-10-2021, 12:33 PM</td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                        <td>
                          <div className="action-button-blk">
                            <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>12-10-2021, 12:33 PM</td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                        <td>
                          <div className="action-button-blk">
                            <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Checkout 