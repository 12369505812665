import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import Routes from './Routes';

function App() {
  return (
    <div className="main-wrapper">
      <Routes></Routes>
    </div>
  );
}

export default App;
