import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class SignUpVerify extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
            <Container>
                <div className="signup-inner-block verification-new-section">
                    <Row>
                        <Col md={6}>
                            <div className="verification-layout">
                                <div className="verify-block">
                                    <h4>Please verify your Mobile Number</h4>
                                    <p>Please enter the verification send to <b>+919999999999</b> <NavLink to="#">Change Number</NavLink></p>
                                    <Form>
                                        <Form.Group className="verify-opt-block">
                                            <Form.Control type="text" placeholder="Enter OTP" />
                                            <Button variant="primary inline-button" type="submit">Verify</Button>
                                        </Form.Group>
                                    </Form>
                                    <h6>Don't receive Code? <NavLink to="#">Resend</NavLink></h6>
                                </div>
                            </div>
                            <div className="verification-layout">
                                <div className="verify-block">
                                    <h4>Please verify your Email Id</h4>
                                    <p>We send verification mail to <b>rahul@gmail.com</b></p>
                                    <h6>Don't receive mail? <NavLink to="#">Resend</NavLink></h6>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="verification-image-blk">
                                <img src= {'images/verify-img.png'} />
                                <h3>Why verification is necessary?</h3>
                                <p>By verifying email address and mobile number trust score will be increased. Profile having good trust score gets more interest from other users.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="verify-button-blk">
                        <Button variant="primary" type="submit" onClick={() => history.push('/SignUpEducationDetails')}>Next</Button>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default SignUpVerify 