import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import history from './../history';

export class ViewMember extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <div className="adcss"></div>
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <Row className="align-items-center">
              <Col md={4}>
                <div className="member-img-block">
                  <img src= {'images/bd-profile-1.jpg'} />
                </div>
              </Col>
              <Col md={8}>
                <div className="member-shortdes-block">
                  <h3>Sonia Rai</h3>
                  <p><span>Active</span> <b>&bull;</b> <span>BD12345</span> <b>&bull;</b> <span>Profile created by Self</span></p>
                  <div className="custom-view-block">
                    <Row>
                      <Col md={6}>
                        <h5><b>Age:</b> <span>25</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Height:</b> <span>5'5"</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Gender:</b> <span>Female</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Mother Tongue:</b> <span>Hindi</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Location:</b> <span>Lucknow</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Religion:</b> <span>Hindu</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Marital Status:</b> <span>Single</span></h5>
                      </Col>
                    </Row>
                    <div className="member-main-btn">
                      <Row>
                        <Col md={4}><Button className="btn btn-primary full-btn"><i className="fas fa-heart"></i> Send Interest</Button></Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="cust-member-blk">
                  <h3>Verifications</h3>
                  <ul className="verify-list-member">
                    <li>Mobile Number Verified</li>
                    <li>Email Id Verified</li>
                  </ul>
                </div>
                <div className="cust-member-blk">
                  <h3>Similer Profiles</h3>
                  <ul className="member-list-blkside">
                    <li>
                      <NavLink to="/ViewMember">
                        <img src= {'images/bd-profile-4.jpg'} />
                        <div className="sidemember-cont">
                          <h4>Preeti Kumari</h4>
                          <p>23 years | MBA | Hindi | Mushlim | Lucknow, Uttar Pradesh</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/ViewMember">
                        <img src= {'images/bd-profile-3.jpg'} />
                        <div className="sidemember-cont">
                          <h4>Preeti Kumari</h4>
                          <p>23 years | MBA | Hindi | Mushlim | Lucknow, Uttar Pradesh</p>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={8}>
                <div className="full-member-details-block">
                <div className="custom-view-block">
                    <h4>Religion Information</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Religion:</b> <span>Hindu</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Caste:</b> <span>Hindu</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Sub Caste:</b> <span>NA</span></h5>
                      </Col>
                    </Row>
                  </div>

                  <div className="custom-view-block">
                    <h4>Location</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Country Living In:</b> <span>India</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>State Living In:</b> <span>Uttar Pradesh</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>City Living In:</b> <span>Lucknow</span></h5>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="custom-view-block">
                    <h4>Habits & Hobbies</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Diet Habits:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Vegetarian:</b> <span>NO</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Smoke Habits:</b> <span>No</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Hobby:</b> <span>NO</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Language Known:</b> <span>Hindi, English</span></h5>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="custom-view-block">
                    <h4>Physical Attribute</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Height:</b> <span>5'8"</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Weight:</b> <span>70 KG</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Body type:</b> <span>Slim</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Complexion:</b> <span>Fair</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Physical Status:</b> <span>NA</span></h5>
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="custom-view-block">
                    <h4>Horoscope Information</h4>
                    <Row>
                      <Col md={6}>
                        <h5><b>Have Dosh?:</b> <span>No</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Dosh Type:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Moonsign(Raasi):</b> <span>Mesh(Aries)</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Star:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Birth Date:</b> <span>NA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Birth Place:</b> <span>NA</span></h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default ViewMember 