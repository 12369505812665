import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import history from './../history';

export class Inbox extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
          <Container>
            <div className="custom-page-heading">
              <h1>Message - Inbox</h1>
              <p>You can see all your inbox messages here.</p>
            </div>
            <div className="matches-cust-blk">
              <Row>
                <Col md="3">
                  <div className="sms-button-block">
                    <Button variant="primary" className="full-btn" onClick={ () => history.push('/SendMessage')}>Send Message</Button>
                    <Button variant="primary" className="full-btn" onClick={ () => history.push('/SendSMS')}>Send SMS</Button>
                  </div>
                  <div className="inbox-sidebar-blk">
                    <ul>
                      <li><NavLink to="/Inbox">Inbox <span>4</span></NavLink></li>
                      <li><NavLink to="/SendList">Send <span>3</span></NavLink></li>
                      <li><NavLink to="/ImportantList">Inportant <span>3</span></NavLink></li>
                    </ul>
                  </div>
                </Col>
                <Col md="9">
                  <div className="inbox-full-blk">
                    <div className="inbox-header-blk">
                      <Row className="justify-content-between">
                        <Col md={4}>
                        <Form.Group>
                          <Form.Control as="select" className="form-select">
                            <option value="">Action</option>
                            <option value="Delete">Delete</option>
                            <option value="Mark as Important">Mark as Important</option>
                          </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col md={5}>
                          <Form.Group className="inbox-searchblk">
                            <Form.Control type="text" placeholder="First Name" />
                            <Button variant="search" type="submit"><i className="fas fa-search"></i></Button>
                        </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div className="inboxtable-blk">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th><Form.Check aria-label="checkall" /></th>
                            <th width="30">Id</th>
                            <th width="150">Name</th>
                            <th>Message</th>
                            <th width="80">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><Form.Check aria-label="check1" /></td>
                            <td>BD0001</td>
                            <td>Aditya Tripathi</td>
                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                            <td>
                              <div className="action-button-blk">
                                <Button variant="reply" type="submit"><i className="fas fa-reply"></i></Button>
                                <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><Form.Check aria-label="check1" /></td>
                            <td>BD0001</td>
                            <td>Aditya Tripathi</td>
                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                            <td>
                              <div className="action-button-blk">
                                <Button variant="reply" type="submit"><i className="fas fa-reply"></i></Button>
                                <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><Form.Check aria-label="check1" /></td>
                            <td>BD0001</td>
                            <td>Aditya Tripathi</td>
                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                            <td>
                              <div className="action-button-blk">
                                <Button variant="reply" type="submit"><i className="fas fa-reply"></i></Button>
                                <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><Form.Check aria-label="check1" /></td>
                            <td>BD0001</td>
                            <td>Aditya Tripathi</td>
                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                            <td>
                              <div className="action-button-blk">
                                <Button variant="reply" type="submit"><i className="fas fa-reply"></i></Button>
                                <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><Form.Check aria-label="check1" /></td>
                            <td>BD0001</td>
                            <td>Aditya Tripathi</td>
                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </td>
                            <td>
                              <div className="action-button-blk">
                                <Button variant="reply" type="submit"><i className="fas fa-reply"></i></Button>
                                <Button variant="delete" type="submit"><i className="far fa-trash-alt"></i></Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <section className="upgrade-membership-section">
          <Container>
            <div className="heading-block-center">
              <h3>Upgrade your membership to connect with those you like.</h3>
              <p>Search your perfect life-partner fron millions of trusted & authentic profiles.</p>
            </div>
            <Row>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/phone.png'} />
                    <h4>View Contacts</h4>
                    <p>Access contact number and connect directly on call or via sms.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/message.png'} />
                    <h4>View Email</h4>
                    <p>Get the email id of your selected profile and reach via email</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="upgrade-block">
                  <img src= {'images/chat.png'} />
                    <h4>Chat</h4>
                    <p>Chat instantly with all other online users</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="button-block">
                  <a href="#" className="viewmore-btn">Browse Membership Plans</a>
                  <p>To know more, call us @ +91 999 999 9999 (India)</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Inbox 