import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class DashboardBasicEdit extends Component {  

  render() {
    const animatedComponents = makeAnimated();
    const options = [
      { value: 'Hindi', label: 'Hindi' },
      { value: 'English', label: 'English' },
      { value: 'Pnajabi', label: 'Pnajabi' },
      { value: 'Arunachali', label: 'Arunachali' },
      { value: 'Assamese', label: 'Assamese' },
      { value: 'Awadhi', label: 'Awadhi' }
    ]
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Basic Details <NavLink to="#">Save</NavLink></h3>
                  <Form>
                    <div className="custom-view-block">
                      <h4>Please enter your details</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name" />
                                <div className="input-text">Same as Document ID</div>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Last Name" />
                                <div className="input-text">Same as Document ID</div>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control type="text" placeholder="Mobile Number" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control type="text" placeholder="Email Id" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Transgender">Transgender</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control type="text" placeholder="Date of Birth" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Mother Tongue</Form.Label>
                                <Form.Control type="text" placeholder="Mother Tongue" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Profile Created By</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Profile Created By</option>
                                    <option value="Self">Self</option>
                                    <option value="Daughter">Daughter</option>
                                    <option value="Son">Son</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Marital Status</Form.Label>
                                <Form.Control type="text" placeholder="Marital Status" />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>About Me</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="About Me" />
                            </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <div className="custom-view-block">
                      <h4>Please enter religion information</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Religion</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Profile Created By</option>
                                    <option value="37" selected="selected">Hindu</option>
                                    <option value="45">Muslim - Others</option>
                                    <option value="44">Muslim - Sunni</option>
                                    <option value="43">Muslim - Shia</option>
                                    <option value="46">Christian</option>
                                    <option value="47">Sikh</option>
                                    <option value="48">Jain - Digambar</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Caste</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Caste</option>
                                    <option value="367">96 Kuli Maratha</option>
                                    <option value="368">Aaru Nattu Vellala</option>
                                    <option value="369">Achirapakkam Chettiar</option>
                                    <option value="370">Ad Dharmi</option>
                                    <option value="371">Adi Andhra</option>
                                    <option value="372">Adi Dravidar</option>
                                    <option value="373">Adi Karnataka</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Sub Caste</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Sub Caste</option>
                                    <option value="207">Kashyap</option>
                                    <option value="208">Muslim</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Will to marry in other caste?</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <div className="custom-view-block">
                      <h4>Please enter your location</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Country Living In</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select</option>
                                    <option value="6">Armenia</option>
                                    <option value="7">Angola</option>
                                    <option value="5">India</option>
                                    <option value="8">Antarctica</option>
                                    <option value="9">Argentina</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>State Living In</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="1315">Uttar pradesh</option>
                                  <option value="1315">Uttrakhand</option>
                                  <option value="1315">Delhi</option>
                                  <option value="1315">Panjab</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>City Living In</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="6">Lucknow</option>
                                  <option value="7">Kanpur</option>
                                  <option value="5">Sitapur</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    
                    <div className="custom-view-block">
                      <h4>Please enter your habits & hobbies</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Diet Habits</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="Vegetarian" selected="">Vegetarian</option>
                                  <option value="Eggetarian">Eggetarian</option>
                                  <option value="Non-Vegetarian">Non-Vegetarian</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Drinking Habits</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="No" selected="">No</option>
                                  <option value="Yes" yes="">Yes</option>
                                  <option value="Occasionally">Occasionally</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Smoke Habits</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="No" selected="">No</option>
                                  <option value="Yes" yes="">Yes</option>
                                  <option value="Drinks Socially">Drinks Socially</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                              <Form.Label>Language known</Form.Label>
                              <Select 
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={options} />
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Hobby</Form.Label>
                                <Form.Control as="textarea" rows={2} placeholder="Hobby" />
                            </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    
                    <div className="custom-view-block">
                      <h4>Please enter physical attribute</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Height</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="48">Below 4ft</option>
                                  <option value="60">5ft</option>
                                  <option value="65">5ft 05in</option>
                                  <option value="72">6ft</option>
                                  <option value="77">6ft 05in</option>
                                  <option value="84">7ft</option>
                                  <option value="85">Above 7ft</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Weight</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="40">40 Kg</option>
                                  <option value="41">45 Kg</option>
                                  <option value="42">50 Kg</option>
                                  <option value="43">60 Kg</option>
                                  <option value="44">65 Kg</option>
                                  <option value="45">75 Kg</option>
                                  <option value="46">80 Kg</option>
                                  <option value="47">85 Kg</option>
                                  <option value="48">90 Kg</option>
                                  <option value="49">95 Kg</option>
                                  <option value="50">100+ Kg</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Body Type</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="Slim">Slim</option>
                                  <option value="Average">Average</option>
                                  <option value="Athletic">Athletic</option>
                                  <option value="Heavy">Heavy</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Complextion</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="Very Fair">Very Fair</option>
                                  <option value="Fair">Fair</option>
                                  <option value="Athletic">Athletic</option>
                                  <option value="Wheatish Brown">Wheatish Brown</option>
                                  <option value="Dark">Dark</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Physical Status</Form.Label>
                                <Form.Control as="select" className="form-select">
                                <option className="">Select Physical Status</option>
                                <option value="Normal">Normal</option>
                                <option value="Physically challenged">Physically challenged</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    
                    <div className="custom-view-block">
                      <h4>Please enter horoscope information</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Dosh</Form.Label>
                                <Form.Control as="select" className="form-select">
                                <option className="">Select Dosh</option>
                                <option value="Manglik">Manglik</option>
                                <option value="Sarpa-dosh">Sarpa-dosh</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Moonsign</Form.Label>
                                <Form.Control as="select" className="form-select">
                                <option value="Does not matter">Does not matter</option>
                                <option value="Mesh (Aries)">Mesh (Aries)</option>
                                <option value="Vrishabh (Taurus)">Vrishabh (Taurus)</option>
                                <option value="Mithun (Gemini)">Mithun (Gemini)</option>
                                <option value="Karka (Cancer)">Karka (Cancer)</option>
                                <option value="Simha (Leo)">Simha (Leo)</option>
                                <option value="Kanya (Virgo)">Kanya (Virgo)</option>
                                <option value="Tula (Libra)">Tula (Libra)</option>
                                <option value="Vrischika (Scorpio)">Vrischika (Scorpio)</option>
                                <option value="Dhanu (Sagittarious)">Dhanu (Sagittarious)</option>
                                <option value="Makar (Capricorn)">Makar (Capricorn)</option>
                                <option value="Kumbha (Aquarious)">Kumbha (Aquarious)</option>
                                <option value="Meen (Pisces)">Meen (Pisces)</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Star</Form.Label>
                                <Form.Control as="select" className="form-select">
                                <option value="">Does not matter</option>
                                <option value="Ashvini">Ashvini</option>
                                <option value="Bharani">Bharani</option>
                                <option value="Krittika">Krittika</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Birth Time</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Birth Time</option>
                                  <option value="Ashvini">Ashvini</option>
                                  <option value="Bharani">Bharani</option>
                                  <option value="Krittika">Krittika</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Birth Place</Form.Label>
                                <Form.Control type="text" placeholder="Birth Place" />
                            </Form.Group>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col md={6}>
                          <div className="center-button-block">
                            <Button variant="primary full-btn" type="submit">Save</Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default DashboardBasicEdit 