import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import Header from './HeaderLogin';
import Footer from './Footer';
import history from './../history';

export class Search extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="innerpage-section">
            <Container>
                <div className="signup-inner-block">
                    <div className="heading-title-block">
                      <h3>Search you life partner</h3>
                      <p>Search perfect partner with our search options which help yout to find out perfect partner for life</p>
                    </div>
                    <div className="custom-view-block">
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Age</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Age</option>
                                    <option value="18-25 Years">18-25 Years</option>
                                    <option value="26-30 Years">26-30 Years</option>
                                    <option value="31-40 Years">31-40 Years</option>
                                    <option value="41-50 Years">41-50 Years</option>
                                    <option value="50+ Years">50+ Years</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Height</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Height</option>
                                    <option value="18-25 Years">4'00" to 4'50"</option>
                                    <option value="18-25 Years">4'50" to 5'00"</option>
                                    <option value="18-25 Years">5'00" to 5'50"</option>
                                    <option value="18-25 Years">5'50" to 6'00"</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Diet</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Diet</option>
                                  <option value="Occasionally Non-Veg">Occasionally Non-Veg</option>
                                  <option value="Vegetarian">Vegetarian</option>
                                  <option value="Non-Vegetarian">Non-Vegetarian</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Smoking</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Smoking</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                  <option value="Smoke Occasionally">Smoke Occasionally</option>
                                  <option value="Never Smoke">Never Smoke</option>
                                  <option value="Smokes Regularly">Smokes Regularly</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Drinking</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Drinking</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                  <option value="Never Drinks">Never Drinks</option>
                                  <option value="Drinks Socially">Drinks Socially</option>
                                  <option value="Drinks Regularly">Drinks Regularly</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Physical Status</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Physical Status</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                  <option value="Normal">Normal</option>
                                  <option value="Physically-challenged">Physically-challenged</option>
                                  <option value="Drinks Regularly">Drinks Regularly</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Looking For</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Looking For</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                  <option value="Never Marride">Never Marride</option>
                                  <option value="Widower">Widower</option>
                                  <option value="Divorced">Divorced</option>
                                  <option value="Awaiting Divorce">Awaiting Divorce</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <div className="custom-view-block">
                      <h4>Religion information</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Religion</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Religion</option>
                                    <option value="Doesn't Matter">Doesn't Matter</option>
                                    <option value="37">Hindu</option>
                                    <option value="45">Muslim - Others</option>
                                    <option value="44">Muslim - Sunni</option>
                                    <option value="43">Muslim - Shia</option>
                                    <option value="46">Christian</option>
                                    <option value="47">Sikh</option>
                                    <option value="48">Jain - Digambar</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Caste</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select Caste</option>
                                    <option value="Doesn't Matter">Doesn't Matter</option>
                                    <option value="367">96 Kuli Maratha</option>
                                    <option value="368">Aaru Nattu Vellala</option>
                                    <option value="369">Achirapakkam Chettiar</option>
                                    <option value="370">Ad Dharmi</option>
                                    <option value="371">Adi Andhra</option>
                                    <option value="372">Adi Dravidar</option>
                                    <option value="373">Adi Karnataka</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Mother Tongue</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Mother Tongue</option>
                                  <option value="Any Mother Tongue">Any Mother Tongue</option>
                                  <option value="Hindi">Hindi</option>
                                  <option value="English">English</option>
                                  <option value="Panjabi">Panjabi</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Manglik</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Manglik</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Star</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Star</option>
                                  <option value="Does not matter">Does not matter</option>
                                  <option value="Bharani">Bharani</option>
                                  <option value="Krittika">Krittika</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <div className="custom-view-block">
                      <h4>Location Details</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Country Living In</Form.Label>
                                <Form.Control as="select" className="form-select">
                                    <option value="">Select</option>
                                    <option value="6">Armenia</option>
                                    <option value="7">Angola</option>
                                    <option value="5">India</option>
                                    <option value="8">Antarctica</option>
                                    <option value="9">Argentina</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>State Living In</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="1315">Uttar pradesh</option>
                                  <option value="1315">Uttrakhand</option>
                                  <option value="1315">Delhi</option>
                                  <option value="1315">Panjab</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>City Living In</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select</option>
                                  <option value="6">Lucknow</option>
                                  <option value="7">Kanpur</option>
                                  <option value="5">Sitapur</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    
                    <div className="custom-view-block">
                      <h4>Education & Occupation Preference</h4>
                      <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Education</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Education</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                  <option value="366">B.A.</option>
                                  <option value="367">M.A</option>
                                  <option value="368">B. Tech</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Occupation</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Occupation</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                  <option value="18">Civil Engineer</option>
                                  <option value="19">Clerical Official</option>
                                  <option value="20">Commercial Pilot</option>
                                  <option value="21">Company Secretary</option>
                                  <option value="22">Computer Professional</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Annual Income</Form.Label>
                                <Form.Control as="select" className="form-select">
                                  <option value="">Select Annual Income</option>
                                  <option value="Doesn't Matter">Doesn't Matter</option>
                                  <option value="Rs 10,000 - 50,000">Rs 10,000 - 50,000</option>
                                  <option value="Rs 50,000 - 1,00,000">Rs 50,000 - 1,00,000</option>
                                  <option value="Rs 1,00,000 - 2,00,000">Rs 1,00,000 - 2,00,000</option>
                                  <option value="Rs 2,00,000 - 4,00,000">Rs 2,00,000 - 4,00,000</option>
                                  <option value="Rs 4,00,000 - 6,00,000">Rs 4,00,000 - 6,00,000</option>
                                  <option value="Rs 6,00,000 - 8,00,000">Rs 6,00,000 - 8,00,000</option>
                                  <option value="Rs 8,00,000 - 10,00,000">Rs 8,00,000 - 10,00,000</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col md={6}>
                          <div className="center-button-block">
                            <Button variant="primary full-btn" type="submit">Search</Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                </div>
            </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default Search 