import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Select } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import history from './../history';

export class SignUp extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <div className="login-signup-section">
            <Container>
                <div className="heading-title-section"></div>
                <Row className="justify-content-center">
                    <Col md="4">
                        <div className="login-inner-block">
                            <div className="heading-title-blk">
                                <h3>Register Free</h3>
                            </div>
                            <div className="form-block">
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email Id</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email Id" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control type="text" placeholder="Mobile Number" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Profile Created for</Form.Label>
                                        <Form.Control as="select" className="form-select">
                                            <option value="">Select</option>
                                            <option value="Self">Self</option>
                                            <option value="Son">Son</option>
                                            <option value="Daughter">Daughter</option>
                                            <option value="orange">Orange</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Create a Password</Form.Label>
                                        <Form.Control type="password" placeholder="Create a Password" />
                                        <div className="input-text">Password should not be less then minimum 8 Alpha Numeric characters</div>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" placeholder="Confirm Password" />
                                    </Form.Group>
                                    <Button variant="primary full-btn" type="submit" onClick={() => history.push('/SignUpProfileDetails')}>Continue</Button>
                                    <div className="login-bottom-blk">
                                        <p>By Logging In, you agree the following <NavLink to="/Terms">Terms & Conditions</NavLink> and the <NavLink to="/Privacy">Privacy Policy</NavLink></p>
                                        <h6>Have an account? <NavLink to="/Login">Click here</NavLink></h6>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default SignUp 