import React,{Component} from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Header from './HeaderLogin';
import Footer from './Footer';
import DashboardNavigation from './DashboardNavigation';

export class DashboardEdu extends Component {  

  render() {
    return (
      <div className="inner-wrapper">
        <Header></Header>
        <section className="user-dashboard-section">
          <Container>
            <Row>
              <Col md={4}>
                <DashboardNavigation></DashboardNavigation>
              </Col>
              <Col md={8}>
                <div className="dashboad-main-block">
                  <h3>Education / Profession Info <NavLink to="/DashboardEduEdit">Edit</NavLink></h3>
                  <div className="custom-view-block">
                    <Row>
                      <Col md={6}>
                        <h5><b>Highest Education:</b> <span>M Arch</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Additional Degree:</b> <span>BCA</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Employed in:</b> <span>Government</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Occupation:</b> <span>Engineer</span></h5>
                      </Col>
                      <Col md={6}>
                        <h5><b>Annual Income:</b> <span>Rs 5 lac - 10 lac</span></h5>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer></Footer>
      </div> 
    );
  }
} 
export default DashboardEdu 